'use strict';

import { applySnapshot, getEnv, getSnapshot, types } from "mobx-state-tree";
import axios from "axios";
import * as Sentry from "@sentry/react";

import { periodsInMs, regionNames, urlify } from "Root/helpers.jsx";

const Location = types.model('Location', { code: '', title: '', server: '' });

const Test = types.model('Test', {
    target: '',
    browser: '',
    viewport: '',
    countries: types.optional(types.array(Location), []),
}).views(self => ({
    get hasTarget() {
        return self.target != null && self.target.length > 0;
    },
    get hasBrowser() {
        return self.browser != null && self.browser.length > 0;
    },
    get hasViewport() {
        return self.viewport != null && self.viewport.length > 0;
    },
})).actions(self => {
    const sessionStore = getEnv(self).safeStore.session;
    const key = 'active-test.v3';

    return {
        set({ target, browser, viewport, countries = [] }) {
            self.target = urlify(target);
            self.countries = [...countries];
            self.browser = browser;
            self.viewport = viewport;
        },
        hydrate() {
            const saved = sessionStore.getItem(key);
            if (saved != null && !self.hasTarget) {
                console.log('hydrating active test', saved)
                self.set(saved);
            }
        },
        store() {
            sessionStore.setItem(key, {
                target: urlify(self.target),
                browser: self.browser,
                viewport: self.viewport,
            });
            console.log('store', sessionStore.getItem(key));
        },
        clearLocations() {
            self.countries = [];
        },
    };
});

const ScheduleInProgress = types.model('ScheduleInProgress', {
    sourceTestId: '',
    target: '',
    locations: types.optional(types.array(Location), []),
    browser: '',
    viewport: '',
    weekdays: types.optional(types.array(types.string), ['Monday']),
    sendEmail: true,
    created: types.maybeNull(types.integer),
}).views(self => ({
    get weekday() {
        return self.weekdays[0];
    },
    get isInProgress() {
        return !!self.sourceTestId.length;
    },
    get isStale() {
        return self.created && self.created + periodsInMs.hour < Date.now();
    },
    get toSnapshot() {
        return getSnapshot(self)
    }
})).actions(self => {
    const localStore = getEnv(self).safeStore.local;
    const key = 'to-schedule.v1';

    return {
        set(schedule) {
            applySnapshot(self, schedule);
        },
        hydrate() {
            const stored = localStore.getItem(key) || {};

            // drop expired schedule data and don't hydrate the model
            if (stored.created && stored.created + periodsInMs.day < Date.now()) {
                self.unstore();

                return;
            }

            self.set(stored);
        },
        store() {
            localStore.setItem(key, {
                weekdays: self.weekdays,
                sendEmail: self.sendEmail,
                sourceTestId: self.sourceTestId,
                browser: self.browser,
                viewport: self.viewport,
                created: Date.now(),
            });
        },
        unstore() {
            localStore.removeItem(key);
        },
        clear() {
            self.unstore();
            applySnapshot(self, {});
        },
        hydrateFromSource() {
            self.hydrate();

            if (!self.isInProgress) {
                return Promise.reject();
            }

            return axios.get(`/api/tests/${self.sourceTestId}`).then(self.onLoadSource);
        },
        onLoadSource(res) {
            self.target = res.data.target;
            self.browser = res.data.browser;
            self.viewport = res.data.viewport?.requested;
            self.locations = res.data.locations.map(l => ({ ...l, code: l.country }))
        }
    };
});

const Server = types.model('Server', {
    name: '',
    title: '',
    city: '',
    up: false,
    continent: '',
    country: '',
    countryName: '',
}).views(self => ({
    get server() {
        return self.name;
    },
}));

const ServerList = types.model('ServerList', {
    servers: types.optional(types.array(Server), []),
    digest: '',
    state: types.optional(types.enumeration('state', ['new', 'success', 'error']), 'new'),
    updated: types.maybeNull(types.Date)
}).views(self => ({
    get isNew() {
        return self.state === 'new';
    },
    get isError() {
        return self.state === 'error';
    },
    get isLoaded() {
        return self.state === 'success';
    },
    get loading() {
        // loads in the background, so all loads after the first already have
        // data unless there was an error
        return self.isNew;
    },
    get up() {
        return self.servers.filter(s => s.up);
    },
    get all() {
        return self.servers.slice();
    },
    byName(name) {
        return self.servers.find(s => s.name === name);
    },
    byNames(names) {
        if (!names || !names.length) {
            return [];
        }

        // preserve the input order
        return names.map(self.byName).filter(s => s);
    },
    byTitle(title) {
        return self.servers.find(s => s.title === title);
    },
    isMissing(name) {
        return self.isLoaded && !!name && !!name.length && !self.byName(name);
    },
})).actions(self => {
    let interval, loader;

    return {
        load() {
            console.log('loading server list');
            if (loader != null) {
                return loader;
            }

            loader = axios.get('/api/servers', { headers: {
                'x-skip-session': 'skip',
                'x-servers-digest': self.digest,
            }})
            .then(self.onLoadSuccess)
            .catch(self.onLoadError)
            .finally(self.onLoadFinally);

            return loader;
        },
        onLoadSuccess(res) {
            // if the server list is new or changed, update our data
            if (!self.digest.length || res.headers['x-servers-digest'] !== self.digest) {
                self.servers = res.data
                    .map(server => ({...server, countryName: regionNames.of(server.country.toUpperCase())}))
                    .sort((a, b) => a.countryName.localeCompare(b.countryName) || a.title.localeCompare(b.title));
                self.digest = res.headers['x-servers-digest'];
                self.updated = new Date();
            }

            self.state = 'success';
        },
        onLoadError(err) {
            console.log(err);
            Sentry.captureException(err || "error loading the server list");
            self.state = 'error';
        },
        onLoadFinally() {
            loader = null;
        },
        startPolling(ms = 600000) {
            console.log('start polling for server list');
            self.stopPolling();

            interval = setInterval(((initialRun) => {
                // initial run only if servers aren't already loaded
                if (initialRun) {
                    self.load();
                }

                return self.load;
            })(self.isNew), ms);
        },
        stopPolling() {
            console.log('stop polling for server list');
            if (interval != null) {
                clearInterval(interval);
            }

            interval = null;
        }
    }
});

export const Session = types.model('Session', {
    isAuthed: false,
    loading: true,
    serverList: types.optional(ServerList, {}),
    activeTest: types.optional(Test, {}),
    toSchedule: types.optional(ScheduleInProgress, {}),
}).views(self => ({
    get hasActiveTest() {
        return self.activeTest.hasTarget();
    }
})).actions(self => ({
    check() {
        self.loading = true;

        return axios.head('/api/login', { headers: { 'x-skip-session': 'skip'}})
        .then(self.onCheck)
        .catch(self.onCheckFailed)
        .finally(self.onCheckFinally);
    },
    onCheck() {
        self.isAuthed = true;
    },
    onCheckFailed(err) {
        self.isAuthed = false;

        throw err;
    },
    onCheckFinally() {
        self.loading = false;
    },
    login(email, password, remember, captcha) {
        return axios.post('/api/login', {email, password, remember, captcha});
    },
    logout() {
        return axios.delete('/api/login')
        .finally(self.onLogout);
    },
    clear() {
        applySnapshot(self, { loading: false });
    },
    onLogout() {
        getEnv(self).safeStore.clear();
        self.clear();
    },
}));
