'use strict';

import { applySnapshot, getRoot, types } from "mobx-state-tree";
import axios from "axios";

import { errmsg, removeArrayItem } from "Root/helpers.jsx";
import { browserTitles } from "Components/browser-chooser.jsx";
import { viewportTitles } from "Components/viewport-chooser.jsx";
import LocationSets from "Models/location-sets.js";
import { TestSchedule } from "Models/test-schedules.js";

const TeamBilling = types.model('TeamBilling', {
    id: '',
    subscriptionId: '',
    state: '',
    plan: '',
    cost: 0,
    periodStart: 0,
    periodEnd: 0,
    billingEnd: types.maybeNull(types.integer),
});

const Team = types.model('Team', {
    id: '',
    members: types.optional(types.array(types.late(() => User)), []),
    inviteCode: '',
    isGlobalAdmin: false,
    schedules: types.optional(types.array(TestSchedule), []),
    maxSchedules: 3,
    locationSets: types.optional(LocationSets, {}),
    throttleDailyRate: 10,
    billing: types.maybeNull(TeamBilling),
    loading: true
}).actions(self => ({
    load() {
        self.loading = true;

        return axios.get('/api/account/team')
        .then(self.onLoad)
        .catch(() => {}) // @todo
        .finally(self.onLoadFinally);
    },
    onLoad(res) {
        applySnapshot(self, {...self, ...res.data});
    },
    onLoadFinally() {
        self.loading = false;
    },
    clear() {
        applySnapshot(self, { loading: false })
    },
    tests({ start, end, scheduled, target, owners, browser, viewport } = {}, limit = 0, page = 0) {
        // date defaults in case they're missing
        start = new Date(start || (new Date()).setDate((new Date()).getDate() - 30));
        end = new Date(end || Date.now());

        // date boundaries in the user's timezone, and sent with timezone information
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        return axios.get('/api/account/team/tests', {
            params: {
                start,
                end,
                scheduled: scheduled,
                target: (target || '').trim().substring(0, 1024),
                limit: +limit,
                page: +page,
                owners,
                browser,
                viewport
            }
        });
    },
    saveNewSchedule(schedule) {
        const created = TestSchedule.create({});

        return created.create(schedule)
            .then(res => {
                self.onScheduleCreate(created);

                if (schedule.sendEmail) {
                    getRoot(self).user.toggleScheduleSub(created.id);
                }

                return res;
            });
    },
    onScheduleCreate(schedule) {
        self.schedules = [...self.schedules.slice(), schedule];
    },
    removeSchedule(schedule) {
        return schedule.delete().then(() => self.onScheduleDelete(schedule.id));
    },
    onScheduleDelete(id) {
        const deleted = self.schedule(id);

        self.schedules = removeArrayItem(self.schedules, deleted);
    },
    generateBillingUrl() {
        return axios.post('/api/account/team/billing/portal').then(res => res.data);
    },
})).views(self => ({
    get hasSchedules() {
        return !!self.schedules.length;
    },
    get hasMaxSchedules() {
        return self.activeSchedules.length >= self.maxSchedules;
    },
    hasSchedule(id) {
        return self.schedules.some(s => s.id === id);
    },
    schedule(id) {
        return self.schedules.find(s => s.id === id);
    },
    get activeSchedules() {
        return self.schedules.filter(s => !s.paused).sort((a, b) => b.created - a.created);
    },
    get pausedSchedules() {
        return self.schedules.filter(s => s.paused).sort((a, b) => b.created - a.created);
    },
    get isSubbed() {
        return self.billing?.state === 'active' || self.billing?.state === 'canceled';
    },
    get subEndsWithBillingPeriod() {
        return !!self.billing?.periodEnd && self.billing?.periodEnd === self.billing?.billingEnd;
    },
    get planName() {
        switch (self.billing?.plan) {
            case 'pro':
                return 'TestLocally Pro';
            default:
                return 'TestLocally Free';
        }
    },
    get isSolo() {
        return self.members.length === 1;
    }
}));

export const User = types.model('User', {
    id: '',
    email: '',
    displayName: '',
    isGlobalAdmin: false,
    emailToVerify: types.maybeNull(types.string),
    team: types.optional(Team, {}),
    schedules: types.optional(types.array(types.string), []),
    features: types.optional(types.array(types.string), []),
    plan: types.maybeNull(types.string),
    loading: true,
}).actions(self => {
    let loader = null;

    return {
        load() {
            if (loader != null) {
                return loader;
            }

            self.loading = true;

            loader = axios.get('/api/account')
            .then(self.onLoad)
            .catch(self.onLoadCatch)
            .finally(self.onLoadFinally);

            return loader;
        },
        onLoad(res) {
            applySnapshot(self, { ...self, ...res.data });
        },
        onLoadCatch(err) {
            err?.response?.status !== 401 && console.log(err);
            self.clear();
        },
        onLoadFinally() {
            self.loading = false;
            loader = null;
        },
        clear() {
            applySnapshot(self, { loading: false })
        },
        changeEmail(email) {
            if (email.trim().toLowerCase() === self.email) {
                return Promise.resolve();
            }
            return axios.patch('/api/account/email', { email })
            .then(self.onUpdate);
        },
        resendVerify() {
            return axios.put('/api/account/verify').catch(err => {
                if (errmsg(err) === 'email-change-canceled') {
                    applySnapshot(self, {...self, emailToVerify: null })
                }

                throw err;
            });
        },
        verifyEmail(token) {
            if (!token || !token.length) {
                return Promise.reject({ response: { data: { message: 'missing-token' }}});
            }

            return axios.post('/api/account/verify', { token })
            .then(self.onUpdate);
        },
        onUpdate(res) {
            applySnapshot(self, {...self, ...res.data})
        },
        toggleScheduleSub(id) {
            return self.updateScheduleSub(id, !self.isScheduleSubbed(id));
        },
        updateScheduleSub(id, subbed) {
            // if we're not changing anything, skip
            if ((subbed && self.isScheduleSubbed(id))
                || (!subbed && !self.isScheduleSubbed(id))
            ) {
                return Promise.resolve();
            }

            const method = subbed ? axios.put : axios.delete;

            return method(`/api/account/schedules/${id}`).then(self.onUpdate);
        },
        checkDeleteStatus() {
            return axios.get('/api/account/delete').then(res => res.data)
        },
        delete() {
            return axios.post('/api/account/delete');
        }
    }
}).views(self => ({
    get isAuthed() {
        return self.email.length > 0;
    },
    get isSubbed() {
        return self.plan && self.plan !== 'free';
    },
    hasFeature(name) {
        return self.isAuthed && self.features.includes(name.trim().toLowerCase());
    },
    get hasChooseBrowser() {
        return self.hasFeature('change-browser-engine');
    },
    get hasChooseViewport() {
        return self.hasFeature('change-viewport-size');
    },
    get hasPdfExport() {
        return self.hasFeature('export-test-to-pdf');
    },
    get hasCompareScreenshots() {
        return self.hasFeature('compare-screenshots');
    },
    // This is for checking if the user has access to the feature, not if any location sets exist.
    get hasLocationSets() {
        return self.hasFeature('location-sets');
    },
    get hasMultiDaySchedules() {
        return self.hasFeature('multiple-weekday-schedules');
    },
    hasBrowser(name) {
        return name === 'chromium'
            || (self.hasChooseBrowser && browserTitles.some(b => b.name === name));
    },
    hasViewport(name) {
        return name === 'desktop'
            || (self.hasChooseViewport && viewportTitles.some(b => b.name === name));
    },
    isScheduleSubbed(scheduleId) {
        return self.schedules.includes(scheduleId);
    }
}));
