'use strict';

import React, { Component } from 'react';

import CityGlobe from 'Images/city-globe.svg';
import { Link } from "react-router-dom";

export const About = () => <>
    <div className="container col-xl-6 py-2">
        <div className="row flex-lg-row align-items-center g-5 py-5">
            <div className="col-sm-5"><CityGlobe /></div>
            <div className="col-sm-7">
                <h1 className="display-3 fw-bold lh-1 mb-3">About Us</h1>
            </div>
        </div>
    </div>
    <div className="container col-xl-6 py-2">
        <p className="lead">
            <Link to="/">TestLocally</Link> takes screenshots of websites
            from different locations around the world, so you can see the
            impact of your localization effort without traveling to your market.
        </p>
        <div className="row">
            <div className="col-sm-6">
                <h3 className="text-center py-3">Who</h3>
                <p>
                    TestLocally is brought to you by <a rel="noopener"
                    href="https://wondernetwork.com">WonderNetwork</a>, a Canadian
                    startup that provides a global network of servers for
                    infrastructure and application testing.
                </p>
            </div>
            <div className="col-sm-6">
                <h3 className="text-center py-3">What</h3>
                <p>
                    We proxy your requests through WonderNetwork servers,
                    configure an appropriate <a rel="noreferrer"
                    href="https://developer.mozilla.org/en-US/docs/Glossary/Locale">locale</a>,
                    capture screenshots with the browser of your choice running
                    under <a rel="noreferrer"
                    href="https://playwright.dev/">Playwright</a>,
                    optimize them for the web with <a rel="noreferrer"
                    href="https://imagemagick.org">ImageMagick</a> and <a
                    rel="noreferrer"
                    href="http://pngnq.sourceforge.net">pngnq</a>, and upload
                    them to <a rel="noreferrer"
                    href="https://aws.amazon.com/s3/">Amazon
                    S3</a> for storage and retrieval.
                </p>
            </div>
        </div>
    </div>
</>;
