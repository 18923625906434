'use strict';

import React from 'react';
import { observer } from "mobx-react-lite";

import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { useMst } from "Models/root.js";
import { LogoutButton } from "Components/logout-button.jsx";
import LogoLink from "Components/logo-link.jsx";

import SettingsIcon from "Images/settings.svg";
import HomeIcon from "Images/dashboard.svg";
import CalendarIcon from "Images/calendar-event.svg";
import TestTubeIcon from "Images/test-tube.svg";
import ListStarsIcon from "Images/list-stars.svg";
import DollarIcon from 'Images/dollar.svg';

const authedNavItems = [
    {
        title: "Account",
        linkTarget: "/account",
        linkText: "Dashboard",
        align: 'align-text-top',
        icon: <HomeIcon width="1em" className="me-2" />
    },
    {
        title: "Tests",
        linkTarget: "/account/history",
        linkText: "Tests",
        align: 'align-middle',
        icon: <TestTubeIcon width="1em" className="me-2" />
    },
    {
        title: "Location sets",
        linkTarget: "/account/location-sets",
        linkText: "Location Sets",
        align: 'align-middle',
        icon: <ListStarsIcon width="1em" className="me-2" />
    },
    {
        title: "Schedules",
        linkTarget: "/account/schedules",
        linkText: "Schedules",
        align: 'align-middle',
        icon: <CalendarIcon width="1em" className="me-2" />
    },
    {
        title: "Account settings",
        linkTarget: "/account/settings",
        linkText: "Settings",
        align: 'align-middle',
        icon: <SettingsIcon width="1em" className="me-2" />
    },
    {
        title: "Account billing",
        linkTarget: "/account/billing",
        linkText: "Billing",
        align: 'align-middle',
        icon: <DollarIcon width="1em" className="me-2" />
    },
]

const AuthedNav = observer(() => {
    const { user } = useMst();
    const location = useLocation();

    // bootstrap will do this for us but react-bootstrap doesn't know how and this
    // is faster than untangling react-bootstrap
    const email = user.email.length <= 25 ? user.email : `${user.email.slice(0, 22)}...`;

    return <Navbar.Collapse id="responsive-navbar-nav">
        { user.isGlobalAdmin && <Nav className="me-auto mt-3 mt-md-0 ms-0 ms-md-2">
            <Nav.Link as={Link} to="/admin" title="Admin" active={location.pathname.indexOf('/admin')===0}>
                Admin
            </Nav.Link>
        </Nav> }
        <Nav className="ms-auto" activeKey={location.pathname}>
            <NavDropdown title={email} id="account-menu">
                {authedNavItems.map(item =>
                    <NavDropdown.Item as={Link}
                                      to={item.linkTarget}
                                      title={item.title}
                                      eventKey={item.linkTarget}
                                      key={item.linkTarget}>
                        {item.icon}
                        <span className={item.align}>{item.linkText}</span>
                    </NavDropdown.Item>
                )}
            </NavDropdown>
            <LogoutButton classes="px-2 py-1 btn btn-outline-primary"/>
        </Nav>
    </Navbar.Collapse>
});

const PublicNav = () => <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ms-auto">
        <Link to="/login" className="mt-2 mt-md-0 px-2 py-1 btn btn-empty text-decoration-none">Log in</Link>
        <Link to="/signup" className="mt-1 mt-md-0 ms-md-1 px-2 py-1 btn btn-outline-primary text-decoration-none">Sign up</Link>
    </Nav>
</Navbar.Collapse>;

const MainNav = observer(() => {
    const { user } = useMst();
    const location = useLocation();

    return <>
        <div className="container col-lg-9 py-2">
            <Navbar collapseOnSelect expand="md">
                <Container>
                    { location.pathname !== '/' && <Navbar.Brand className="text-primary">
                        <LogoLink />
                    </Navbar.Brand> }
                    { user.isAuthed &&
                        <Nav.Link
                            className={`fw-bold badge bg-${user.isSubbed ? 'secondary' : 'danger'} text-white my-auto fs-6 text-capitalize`}
                            as={Link} to="/account/billing" title="Billing"
                            active={location.pathname.startsWith('/account/billing')}>
                        {user.plan || 'Free'}
                        </Nav.Link>
                    }
                    <Navbar.Toggle className="ms-auto" aria-controls="responsive-navbar-nav"/>
                    { user.isAuthed ? <AuthedNav /> : <PublicNav /> }
                </Container>
            </Navbar>
        </div>
    </>;
});

export default MainNav;
