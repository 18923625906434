import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import axios from "axios";

export const TestSchedule = types.model('TestSchedule', {
    target: '',
    browser: '',
    viewport: '',
    servers: types.optional(types.array(types.string), []),
    weekdays: types.optional(types.array(types.string), []),
    id: '',
    creatorEmail: '',
    created: 0,
    paused: false,
    loading: false,
}).views(self => ({
    get weekday() {
        return self.weekdays[0];
    },
    unavailableServers(serverList) {
        return self.servers.filter(serverList.isMissing);
    },
    activeServers(serverList) {
        return self.servers.filter(s=> !serverList.isMissing(s));
    },
    isServerMatch(servers) {
        // we care about order
        return self.servers.length === servers.length
            && self.servers.every((s, idx) => servers[idx] === s);
    },
    isWeekdayMatch(weekdays) {
        // we don't care about order
        return self.weekdays.length === weekdays.length
            && self.weekdays.every(w => weekdays.includes(w));
    },
    isMatch({ target, browser, viewport, servers, weekdays }) {
        return self.target === target
            && self.browser === browser
            && self.viewport === viewport
            && self.isServerMatch(servers)
            && self.isWeekdayMatch(weekdays);
    },
    willRun(missingServers) {
        return !missingServers.length && !self.paused;
    },
})).actions(self => ({
    create({ target, browser, viewport, servers, locations, weekdays }) {
        self.loading = true;

        return axios.post('/api/account/team/schedules', {
            target,
            browser,
            viewport,
            servers: servers || locations.map(l => l.server),
            weekdays,
        }).then(self.onCreate)
        .finally(self.onDoneLoading);
    },
    onCreate(res) {
        applySnapshot(self, res.data);

        return res;
    },
    onDoneLoading() {
        self.loading = false;
    },
    unpause() {
        self.loading = true;

        return axios.put(`/api/account/team/schedules/${self.id}/paused`, {
            paused: false
        }).then(self.onUpdate)
        .finally(self.onDoneLoading);
    },
    update({ browser, viewport, servers, weekdays }) {
        // if there are no changes, skip
        if (self.isMatch({target: self.target, browser, viewport, servers, weekdays})) {
            return Promise.resolve();
        }

        self.loading = true;

        return axios.patch(`/api/account/team/schedules/${self.id}`, {
            browser,
            viewport,
            servers,
            weekdays,
        }).then(self.onUpdate)
        .finally(self.onDoneLoading);
    },
    onUpdate(res) {
        applySnapshot(self, {...self, ...res.data});
    },
    delete() {
        self.loading = true;

        return axios.delete(`/api/account/team/schedules/${self.id}`)
        .finally(self.onDoneLoading);
    }
}));
