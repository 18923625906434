'use strict';

import React, { Component, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { PlausibleContext } from "Root/plausible.jsx";

export const NotFound = ({ to = '/' }) => {
    const { trackEvent } = useContext(PlausibleContext);

    useEffect(() => {
        trackEvent('404');
    }, []);

    return <div className="container col-lg-8 py-2">
        <div className="row flex-lg-row py-5">
            <h1 className="display-6 fw-bold lh-1 mb-3 text-center">
                Page not found
            </h1>
        </div>
    </div>;
}
