'use strict';

import React, { Component } from 'react';
import MarkdownPage from "Components/markdown-page.jsx";
import {useQueryParams, useTitle} from "Root/helpers.jsx";

export const Terms = () => {
    const { date } = useQueryParams();

    useTitle('Terms of Service');

    const source = date?.match(/\d{4}-\d{2}-\d{2}/)
        ? `terms-of-service.${date}`
        : `terms-of-service`;

    return <>
        <div className="container col-xl-6 py-2">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <h1 className="display-3 fw-bold lh-1 mb-3 text-center">
                    Terms of Service
                </h1>
            </div>
        </div>
        <div className="container col-xl-6 py-2">
            <MarkdownPage title="Terms of Service" sourcePath={source} />
        </div>
    </>;
}
