'use strict';

import React, { useState, useCallback, useContext } from 'react';
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { PlausibleContext } from "Root/plausible.jsx";

import ChromiumLogo from "Images/chromium.svg";
import ChromeLogo from "Images/chrome.svg";
import EdgeLogo from "Images/edge.svg";
import FirefoxLogo from "Images/firefox.svg";
import SafariLogo from "Images/safari.svg";

const known = [{
    name: 'chromium',
    title: 'Chromium',
}, {
    name: 'chrome',
    title: 'Google Chrome',
}, {
    name: 'msedge',
    title: 'Microsoft Edge',
}, {
    name: 'firefox',
    title: 'Mozilla Firefox',
}, {
    name: 'webkit',
    title: 'Apple Safari',
}];

const Logo = ({engine, ...rest}) => {
    switch(engine) {
        case 'chromium':
            return <ChromiumLogo {...rest} />;
        case 'chrome':
            return <ChromeLogo {...rest} />;
        case 'msedge':
            return <EdgeLogo {...rest} />;
        case 'firefox':
            return <FirefoxLogo {...rest} />;
        case 'webkit':
            return <SafariLogo {...rest} />;
    }
}

const BrowserChooser = ({ choose, choice, size = "lg" }) => {
    const { trackEvent } = useContext(PlausibleContext);

    const onChange = useCallback(e => {
        const browser = known.find(b => b.name === e.target.value);

        trackEvent(`choose-browser`, { props: { browser: browser.name } });

        choose(browser);
    }, [choose, known]);

    return <>
        <ButtonGroup size={size}>
            {known.map(({name, title}) => {
                return <ToggleButton id={name}
                                     key={name}
                                     title={title}
                                     type="radio"
                                     variant="outline-light"
                                     name="browser"
                                     checked={choice === name}
                                     onChange={onChange}
                                     value={name}>
                    <Logo engine={name} height="1.3em"/>
                </ToggleButton>;
            })}
        </ButtonGroup>
    </>;
};

export default BrowserChooser;

export const browserTitles = known;
export const BrowserLogo = Logo;
