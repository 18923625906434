'use strict';

import React from 'react';
import { useParams } from "react-router-dom";

import { NotFound } from "Pages/not-found.jsx";

export const OnlyMatchedElement = ({ patterns, children}) => {
    const params = useParams();

    const paramsToMatch = Object.keys(patterns);

    // if we're not matching anything, load the children
    if (!paramsToMatch.length) {
        return children;
    }

    // only load the children if the parameters match our patterns
    return paramsToMatch.every(param => params[param]?.match(patterns[param]))
        ? children
        : <div className="container col-xl-6 py-2"><NotFound /></div>
}
