'use strict';

import React, { Component } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { NotFound } from "Pages/not-found.jsx";
import { useTitle, lazyWithRetry } from "Root/helpers.jsx";
import CustomSuspense from "Components/custom-suspense.jsx";

const Home = lazyWithRetry(() => import("Pages/home.jsx"));

export const adlanders = {
    '/website-screenshot-generator': {
        title: 'Website screenshot generator',
        header: 'Quickly generate screenshots of websites',
        copy: 'Take screenshots of any website in seconds. TestLocally has systems around the world to take screenshots showing localized copy for those locations.',
    },
    '/automatic-website-screenshots': {
        title: 'Automatic website screenshots',
        header: 'Automatically take screenshots of websites',
        copy: 'Automatically grab screenshots every week to keep an eye out for changes, direct to your inbox.',
    },
    '/scheduled-websites-screenshots': {
        title: 'Scheduled website screenshots',
        header: 'Schedule screenshots of websites',
        copy: 'Schedule screenshots for any day of the week, delivered to your inbox, viewable online for months. ',
    },
    '/full-website-screenshots': {
        title: 'Full website screenshots',
        header: 'Full-sized screenshots of websites',
        copy: 'Take a screenshot of the whole page, from anywhere in the world. Automate them later if you need it. ',
    },
    '/screenshots-from-different-countries': {
        title: 'Screenshots from different countries',
        header: 'Screenshots from 20+ countries',
        copy: 'TestLocally has servers all around the world ready to show you the localized content served to users in that region. Get access to more locations when you sign up for a free account.',
    }
};

const Ad = ({title, header, copy}) => {
    useTitle(title);

    return <CustomSuspense><Home>
        <h2 className="fs-3 my-4">{header}</h2>
        <p className="lead my-4">{copy}</p>
    </Home></CustomSuspense>;
}

export const AdLander = () => {
    const { pathname } = useLocation();

    return adlanders[pathname] == null
        ? <NotFound />
        : <Ad {...adlanders[pathname]} />;
};
