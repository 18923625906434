import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

const AccountDeleted = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location?.state?.accountDeleted) {
            navigate('/', {replace: true});
        };
    }, []);

    return <>
        <div className="container col-xl-6 py-2">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <h1 className="display-3 fw-bold lh-1 mb-3 text-center">
                    Account deleted
                </h1>
            </div>
        </div>
        <div className="container col-xl-6 py-2">
            <Alert variant="warning">
                <p className="mb-0 text-center">Your account has been deleted. Thanks for using TestLocally.</p>
            </Alert>
        </div>
    </>;
};

export default AccountDeleted;
