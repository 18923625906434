'use strict';

import React, { useState, useEffect, useCallback } from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkSmartypants from "remark-smartypants";
import * as Sentry from "@sentry/react"
import { NotFound } from "Pages/not-found.jsx";

const MarkdownPage = ({sourcePath}) => {
    const [contentError, setContentError] = useState(false);
    const [content, setContent] = useState();

    const loadMarkdownContent = useCallback(source => {
        // Some part of the import string needs to be explicit in the string for this to work.
        // https://pablo.gg/en/blog/coding/how-webpack-handles-dynamic-imports-with-variable-paths/#the-magic-trick
        import(`Markdown/${source}.md`).then(md => setContent(md.default)).catch(err => {
            Sentry.captureException(err);

            setContent();
            setContentError(true);
        });
    }, []);

    // Grab the page content from the script tag.
    useEffect(() => {
        setContent();
        setContentError(false);

        // set up preloaded data on first load if there is any
        const preloadElement = document.getElementById('markdown-content');

        if (preloadElement) {
            try {
                const content = JSON.parse(preloadElement.textContent);

                content ? setContent(content) : loadMarkdownContent(sourcePath);
            } catch (err) {
                // don't choke if there's something wrong with the data
                Sentry.captureException(err);
                setContentError(true);
            } finally {
                // pull the preloaded data so future renders get updates
                preloadElement.parentNode.removeChild(preloadElement);
            }

            return;
        }

        loadMarkdownContent(sourcePath);
    }, [sourcePath]);

    if (content) {
        return <ReactMarkdown className="markdown-page" remarkPlugins={[remarkGfm, remarkSmartypants]}>
            {content}
        </ReactMarkdown>;
    }

    if (contentError) {
        return <NotFound />;
    }

    return null;
};

export default MarkdownPage;