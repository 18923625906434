'use strict';

import React from 'react';
import { DropdownButton } from "react-bootstrap";


const CuteDropdownButton = ({ title, children, className, ...rest }) => <>
    <DropdownButton
        title={title}
        className={`cute-dropdown d-grid ${className || ''}`}
        {...rest}
    >
        {children}
    </DropdownButton>
</>;

export default CuteDropdownButton
