import { applySnapshot, types } from "mobx-state-tree";
import axios from "axios";

const LocationSet = types.model('LocationSet', {
    id: '',
    name: '',
    servers: types.optional(types.array(types.string), []),
    deleting: false,
    updating: false,
}).actions(self => ({
    delete() {
        self.deleting = true;

        return axios.delete(`/api/account/team/location-sets/${self.id}`)
        .finally(self.onDeleteFinally);
    },
    onDeleteFinally() {
        self.deleting = false;
    },
    update(name, servers) {
        self.updating = true;

        return axios.patch(`/api/account/team/location-sets/${self.id}`, { name, servers })
        .then(self.onUpdate)
        .finally(self.onUpdateFinally);
    },
    onUpdateFinally() {
        self.updating = false;
    },
    onUpdate(res) {
        applySnapshot(self, res.data);
    }
})).views(self => ({
    get hasServers() {
        return !!self.servers.length;
    },
    isServerMatch(servers) {
        if (!servers || !servers?.length || servers?.length !== self.servers.length) {
            return false;
        }

        return servers.every(s => self.servers.includes(s))
            && self.servers.every(s => servers.includes(s));
    },
    includesAnyFrom(servers) {
        return self.servers.some(s => servers.includes(s));
    },
    get loading() {
        return self.deleting || self.updating;
    }
}));

const LocationSets = types.model('LocationSets', {
    sets: types.optional(types.array(LocationSet), []),
    loading: true,
    creating: false,
}).actions(self => {
    let loader = null;

    return {
        create(set) {
            self.creating = true;

            return axios.post('/api/account/team/location-sets', {
                name: set.name.trim(),
                servers: set.servers,
            })
            .then(self.onCreate)
            .finally(self.onCreateFinally);
        },
        onCreate(res) {
            self.sets = [...self.sets, res.data];

            return res.data;
        },
        onCreateFinally() {
            self.creating = false;
        },
        load() {
            if (!loader) {
                loader = axios.get('/api/account/team/location-sets')
                .then(self.onLoad)
                .finally(self.onLoadFinally);
            }

            return loader;
        },
        onLoad(res) {
            applySnapshot(self.sets, res.data);
        },
        onLoadFinally() {
            self.loading = false;
            loader = null;
        },
        delete(id) {
            const set = self.get(id);
            if (!set) {
                return Promise.resolve();
            }

            return set.delete().then(() => self.onDelete(id));
        },
        onDelete(id) {
            self.sets = self.sets.filter(s => s.id !== id);
        },
        update({ id, name, servers }) {
            const set = self.get(id);
            if (!set) {
                return Promise.reject();
            }

            return set.update(name, servers);
        },
    }
}).views(self => ({
    get isEmpty() {
        return !self.sets.length;
    },
    get length() {
        return self.sets.length;
    },
    get(id) {
        return self.sets.find(s => s.id === id);
    },
    hasDuplicateName(set) {
        const found = self.getByName(set.name);

        return found && found.id !== set.id;
    },
    getByName(name) {
        return self.sets.find(s => s.name.toLowerCase().trim() === name.toLowerCase().trim());
    },
    get all() {
        return self.sets.slice();
    }
}));

export default LocationSets;
