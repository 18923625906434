'use strict';

import React from 'react';

export const PlausibleContext = React.createContext(null);

const safe = (callback, args) => {
    try {
        return callback(...args);
    } catch (e) {
        // fail silently
    }
}

export const safePlausible = plausible => ({
    trackEvent: (...args) => safe(plausible.trackEvent, args),
    trackPageview: (...args) => safe(plausible.trackPageview, args),
    enableAutoPageviews: (...args) => safe(plausible.enableAutoPageviews, args),
    enableAutoOutboundTracking: (...args) => safe(plausible.enableAutoOutboundTracking, args),
});
