'use strict';

import React, { Component } from 'react';

export const Privacy = () => <>
    <div className="container col-xl-6 py-2">
        <div className="row flex-lg-row align-items-center g-5 py-5">
            <h1 className="display-3 fw-bold lh-1 mb-3 text-center">
                Privacy Policy
            </h1>
        </div>
    </div>
    <div className="container col-xl-6 py-2">
        <p><small className="text-muted">Last updated: {(new Date('2021-12-23 12:00')).toLocaleString(undefined, { dateStyle: 'full' })}</small></p>
        <p>
            When you use this service, we collect and store your IP address and
            basic browser information. Our servers access the websites and
            screenshots you request using the service, and the screenshots are
            stored on <a rel="noreferrer" href="https://aws.amazon.com/s3/">Amazon's Simple
            Storage Service (S3)</a>.
        </p>
        <p>
            If you sign up for an account, we collect and store your email
            address. When you log in, we maintain your login session with a
            cookie saved on your device.
        </p>
        <p>
            We use <a rel="noreferrer" href="https://plausible.io">Plausible</a>, a
            privacy-focused web analytics service, to collect information about
            how people use this website. Plausible does not set any cookies or
            collect any personal information.
        </p>
        <p>
            We use <a rel="noreferrer" href="https://sentry.io">Sentry</a> to help us track and
            resolve any bugs you might find. Sentry collects your platform
            information and your IP address. It does not set any cookies or
            collect any other personal information.
        </p>
        <p>
            We use <a rel="noreferrer" href="https://doorbell.io">Doorbell</a> to help you provide
            feedback about this service. Doorbell collects your platform information,
            your IP address, and the information you provide as feedback. It
            does not set any cookies or collect any other personal information.
        </p>
        <p>
            If you sign up for a paid subscription, we use <a rel="noreferrer"
                                                              href="https://stripe.com">Stripe</a> to manage your billing details.
            Stripe collects your payment information, email address, IP address,
            and any other information you provide. We never receive your credit
            card information. You can read more about <a rel="noreferrer"
                                                         href="https://stripe.com/privacy">Stripe's privacy policy</a>.
        </p>
        <p>
            We may use the information we collect to:
        </p>
        <ul>
            <li>Provide support and customer service</li>
            <li>Improve our services</li>
            <li>Identify and prevent undesirable behavior</li>
        </ul>
        <p>
            We don't sell, rent, lease, lend, loan, or give away any of this information.
        </p>
    </div>
</>;