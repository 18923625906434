import React from "react";
import { Spinner } from "react-bootstrap";
import { prefersReducedMotion } from "Root/helpers.jsx";
import LoadingText from "Components/loading-text.jsx";

const CustomSpinner = ({ fallbackText, variant="primary", size="md", containerStyle={} }) => {
    const text = fallbackText || "Loading"
    return <div className="text-center m-auto d-flex justify-content-center align-items-center" style={containerStyle}>
        {prefersReducedMotion ? <LoadingText text={text}/>
            : <Spinner className="m-auto" size={size} animation="border" variant={variant} role="status">
                <span className="visually-hidden">{text}</span>
            </Spinner>}
    </div>
}

export default CustomSpinner
