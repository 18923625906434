import React, { useEffect } from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate, Outlet,
} from "react-router-dom";
import Plausible from "plausible-tracker";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import { PlausibleContext, safePlausible } from "./plausible.jsx";
import { Terms } from "Pages/terms.jsx";
import { Privacy } from "Pages/privacy.jsx";
import { About } from "Pages/about.jsx";
import { adlanders, AdLander } from "Pages/ad-lander.jsx";
import { seoLanders, SeoLander } from "Pages/seo-lander.jsx";
import { NotFound } from "Pages/not-found.jsx";
import AccountDeleted from "Pages/account-deleted.jsx";
import Footer from "Components/footer.jsx";
import MainNav from "Components/nav.jsx";
import ScrollToTop from 'Components/scroll-to-top.jsx';
import CustomSuspense from "Components/custom-suspense.jsx";
import { templateConfig, lazyWithRetry } from "./helpers.jsx";
import { StoreProvider, store, useMst } from 'Models/root.js';
import { OnlyMatchedElement } from "Components/only-matched-element.jsx";

import 'Sass/base.scss';

const AccountRoutes = lazyWithRetry(() => import("Pages/account/routes.jsx"));
const AdminRoutes = lazyWithRetry(() => import("Pages/admin/routes.jsx"));
const LoginRoutes = lazyWithRetry(() => import("Pages/login/routes.jsx"));
const TestResults = lazyWithRetry(() => import("Pages/test-results.jsx"));
const Signup = lazyWithRetry(() => import("Pages/signup.jsx"));
const Home = lazyWithRetry(() => import("Pages/home.jsx"));
const Showcase = lazyWithRetry(() => import("Pages/showcase.jsx"));
const Pricing = lazyWithRetry(() => import("Pages/pricing.jsx"));
const Locations = lazyWithRetry(() => import("Pages/locations.jsx"));
const Doorbell = lazyWithRetry(() => import("Components/doorbell.jsx"));

// Suppress console logs on production env
if (process.env.NODE_ENV === 'production') {
    window.console.log = function() {};
    window.console.debug = function() {};
}

// anti-crsf
// https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#use-of-custom-request-headers
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Sentry.init({
    ...templateConfig('sentry'),
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    sampleRate: 1,
    autoSessionTracking: true,
    beforeSend(event, hint) {
        const err = hint.originalException;

        // if we're sending an error from a axios request, add some details
        if (err?.response) {
            event.extra = {
                ...event.extra,
                response: err.response.data,
                url: err.response.config?.url,
                method: err.response.config?.method,
            }
        }

        return event;
    }
});

const plausible = safePlausible(Plausible({domain: templateConfig('plausibleDomain')}));

const PublicLayout = () => {
    const { user } = useMst();

    useEffect(() => {
        user.load();
    }, []);

    return <>
        <MainNav />
        <Outlet/>
        <CustomSuspense visible={false}><Doorbell/></CustomSuspense>
    </>;
}

const Index = () => {
    return <>
        <BrowserRouter>
            <ScrollToTop>
                <PlausibleContext.Provider value={plausible}>
                    <StoreProvider value={store} >
                        <div className="d-flex flex-column min-vh-100">
                            <Routes>
                                <Route element={<PublicLayout />}>
                                    <Route path="/" element={<CustomSuspense><Home/></CustomSuspense>}/>
                                    <Route path="/terms" element={<Terms />}/>
                                    <Route path="/privacy" element={<Privacy />}/>
                                    <Route path="/about" element={<About />}/>
                                    <Route path="/showcase" element={<CustomSuspense><Showcase/></CustomSuspense>}/>
                                    <Route path="/locations" element={<CustomSuspense><Locations/></CustomSuspense>}/>
                                    <Route path="/pricing" element={<CustomSuspense><Pricing/></CustomSuspense>}/>
                                    <Route path="/tests">
                                        <Route index element={<Navigate to="/" />} />
                                        <Route path=":id" element={
                                            <OnlyMatchedElement patterns={{ id: /^[a-f0-9]{24}$/i }}>
                                                <CustomSuspense><TestResults /></CustomSuspense>
                                            </OnlyMatchedElement>
                                        } />
                                    </Route>
                                    <Route path="/login/*" element={<CustomSuspense><LoginRoutes /></CustomSuspense>} />
                                    <Route path="/signup" element={<CustomSuspense><Signup /></CustomSuspense>} />
                                    <Route path="/account-deleted" element={<AccountDeleted />}/>
                                    {Object.keys(adlanders).map(path =>
                                        <Route key={path} path={path} element={<AdLander />} />
                                    )}
                                    {Object.keys(seoLanders).map(path =>
                                        <Route key={path} path={path} element={<SeoLander />} />
                                    )}
                                    <Route path="*" element={<div className="container col-xl-6 py-2"><NotFound /></div>}/>
                                </Route>
                                <Route path="/account/*" element={<CustomSuspense><AccountRoutes /></CustomSuspense>}/>
                                <Route path="/admin/*" element={<CustomSuspense><AdminRoutes /></CustomSuspense>}/>
                            </Routes>
                            <div className="mb-5"/>
                            <Footer/>
                        </div>
                    </StoreProvider>
                </PlausibleContext.Provider>
            </ScrollToTop>
        </BrowserRouter>
        <Toaster toastOptions={{
            error: { duration: 6000, iconTheme: { primary: '#EA7363' }},
            success: { iconTheme: { primary: '#008466' }}
        }} />
    </>;
}

plausible.enableAutoPageviews();

const root = createRoot(document.getElementById('app'))
root.render(<Index />, );
