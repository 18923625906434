'use strict';

import React, { Component, useContext } from 'react';
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { useMst } from "Models/root.js";
import { PlausibleContext } from "Root/plausible.jsx";

export const LogoutButton = observer(({ classes = "btn btn-primary", children = "Log out" }) => {
    const { trackEvent } = useContext(PlausibleContext);
    const navigate = useNavigate();
    const { session, user } = useMst();

    const logout = e => {
        e.preventDefault();

        session.logout().then(() => navigate('/')).then(() => user.clear()).then(() => trackEvent('logout'));
    }

    return <button type="button" className={classes} onClick={logout}>{children}</button>
});
