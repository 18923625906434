'use strict';

import React, { Component } from 'react';
import { Link } from "react-router-dom";

import TwitterIcon from 'Images/twitter.svg';
import MastodonIcon from "Images/mastodon.svg";
import EmailIcon from 'Images/email.svg';

import './footer.scss';
import LogoLink from "Components/logo-link.jsx";

export default () => (
    <div className="d-print-none mt-auto footer">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 offset-sm-1">
                    <h3><LogoLink /></h3>
                    <p>
                        Screenshots requested using this service are copyright their respective website owners.
                    </p>
                    <p>
                        Everything else © {(new Date().getFullYear())} <a href="https://wondernetwork.com">WonderNetwork</a>.
                    </p>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Learn</h3>
                            <ul>
                                <li><Link to="/showcase">Showcase</Link></li>
                                <li><Link to="/locations">Locations</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                            </ul>
                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                <li><Link to="/terms">Terms of Service</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <h3>Contact</h3>
                            <ul>
                                <li><a href="mailto:hello@testlocal.ly">
                                    <EmailIcon width="20px" style={{ marginRight: '0.5em'}} />Email
                                </a></li>
                                <li><a target="_blank" rel="noreferrer" href="https://fedi.wondernetwork.com/@testlocally">
                                    <MastodonIcon width="20px" style={{ marginRight: '0.5em'}} />Mastodon
                                </a></li>
                                <li><a target="_blank" rel="noreferrer" href="https://twitter.com/testlocally">
                                    <TwitterIcon width="20px" style={{ marginRight: '0.5em'}} />Twitter
                                </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <ul>
                            <li><Link to="/automatic-screenshots">Automatic screenshots</Link></li>
                            <li><Link to="/screenshot-service">Screenshot service</Link></li>
                            <li><Link to="/view-website-from-another-country">View website from another country</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
