'use strict';

import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Logo from "Images/logo.svg";

const LogoLink = () => <Link to="/" className="fw-bold text-decoration-none">
    <Logo height="1.3em" style={{marginRight: '5px'}}/>
    TestLocally
</Link>

export default LogoLink;
