'use strict';

import React, { Suspense } from 'react';
import CustomSpinner from "Components/custom-spinner.jsx";

const CustomSuspense = ({ children, visible=true }) => <Suspense fallback={
    visible ? <CustomSpinner /> : <div />
}>{children}</Suspense>;

export default CustomSuspense
