"use strict";

import { applySnapshot, onPatch, types } from "mobx-state-tree";
import React, { useContext } from "react";

import { User } from "Models/user.js";
import { Session } from "Models/session.js";

// testlocally doesn't NEED browser storage, so just make everything fail
// silently if storage is disabled
export const makeSafeStore = () => {
    // trying to access local or session storage when storage is disabled throws
    // an exception, so wrap access in a function
    const local = () => window.localStorage;
    const session = () => window.sessionStorage;

    const get = (store, key) => {
        let item;

        try {
            item = store().getItem(key);
        } catch (e) {
            return null;
        }

        // return a parsed json object if it's json, otherwise return raw
        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }
    };

    const remove = (store, key) => {
        try {
            store().removeItem(key);
        } catch (e) {
            // fail silently
        }
    };

    const set = (store, key, value) => {
        try {
            return store().setItem(key, JSON.stringify(value));
        } catch (e) {
            // fail silently
        }
    };

    const clear = store => {
        try {
            store().clear();
        } catch (e) {
            // fail silently
        }
    };

    const canStoreData = store => {
        try {
            set(store, 'store-test', { canStore: true })
            const canStore = get(store, 'store-test').canStore
            remove(store, 'store-test')
            return canStore
        } catch (e) {
            return false
        }
    };

    return {
        local: {
            clear: () => clear(local),
            getItem: key => get(local, key),
            removeItem: key => remove(local, key),
            setItem: (key, value) => set(local, key, value),
            canStoreData: () => canStoreData(local),
        },
        session: {
            clear: () => clear(session),
            getItem: key => get(session, key),
            removeItem: key => remove(session, key),
            setItem: (key, value) => set(session, key, value),
            canStoreData: () => canStoreData(session),
        },
        clear: () => {
            clear(local);
            clear(session);
        },
    };
};

export const Store = types.model('Store', {
    session: types.optional(Session, {}),
    user: types.optional(User, {}),
})

export const store = Store.create({}, {
    safeStore: makeSafeStore(),
});
onPatch(store, (patch) => {
    // this is a little noisy
    console.log('onPatch', patch);
});

const StoreContext = React.createContext(null);
export const StoreProvider = StoreContext.Provider;
export const useMst = () => {
    const context = useContext(StoreContext);
    if (context == null) {
        throw new Error('You need to set up the root store');
    }

    return context;
};
