import React, { useMemo, useState } from 'react';
import { useInterval } from 'Root/helpers.jsx'

const LoadingText = ({ text="Loading", timeout=2000, maxDots=3, textOnly=false }) => {
    const [visibleDots, setVisibleDots] = useState(maxDots);

    useInterval(()=> {
        setVisibleDots(visibleDots < maxDots ? visibleDots + 1 : 0);
    }, timeout, visibleDots);

    const dots = useMemo(() => Array(maxDots).fill('.'), [maxDots]);

    if (textOnly) {
        /*
            Returning only the actual text may be cleaner in some cases, but may
            cause layout jump or similar unexpected movement due to surrounding
            elements' styles. Try textOnly={true} if output moves weird.
        */
        return text + '.'.repeat(visibleDots);
    }

    return <span>
        {text}{dots.map((dot, i) =>
            <span key={i} style={{ opacity: +(visibleDots > i), transition: 'opacity 0.25s' }}>
                {dot}
            </span>
        )}
    </span>
};

export default LoadingText
