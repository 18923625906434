'use strict';

import React, { Component, useCallback, useContext, useState } from 'react';

import Desktop from 'Images/viewport-desktop.svg';
import Tablet from 'Images/viewport-tablet.svg';
import Phone from 'Images/viewport-phone.svg';
import { PlausibleContext } from "Root/plausible.jsx";
import CuteDropdownButton from "Components/cute-dropdown-button.jsx";
import { Dropdown } from "react-bootstrap";

const known = [
    // scalars only so we don't build unnecessary components on every page load
    { name: 'desktop', title: 'Desktop' },
    { name: 'tablet', title: 'Tablet' },
    { name: 'phone', title: 'Phone' }
];

const Logo = ({ viewport, ...rest }) => {
    switch(viewport) {
        case 'desktop':
            return <Desktop {...rest} />;
        case 'tablet':
            return <Tablet {...rest} />;
        case 'phone':
            return <Phone {...rest} />;
    }
};

const DropdownTitle = ({ viewport }) => <span title={`${viewport.title}-sized browser`}>
    <Logo viewport={viewport.name} height="1.2em"/>
</span>;

const ViewportChooser = ({ choose, choice, size = "lg" }) => {
    const { trackEvent } = useContext(PlausibleContext);

    const onSelect = useCallback(viewport => {
        if (choice === viewport) {
            return;
        }

        if (!known.map(v => v.name).includes(viewport)) {
            return;
        }

        trackEvent('choose-viewport', {props: {viewport}});

        choose(viewport);
    }, [choice]);

    return <>
        <CuteDropdownButton title={<DropdownTitle viewport={known.find(({name})=> name === choice)} />}
                            size={size}
                            variant="outline-light"
                            align="end"
                            onSelect={onSelect}
                            className="justify-content-end">
            {known.map(v => <Dropdown.Item key={v.name} eventKey={v.name} title={`${v.title}-sized browser`} active={choice === v.name}>
                <Logo viewport={v.name} height="1.1em"/>
            </Dropdown.Item>)}
        </CuteDropdownButton>
    </>
};

export const viewportTitles = known;
export const ViewportLogo = Logo;
export default ViewportChooser;
